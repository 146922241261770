<template>
  <div
    class="row justify-center bg-grey-3"
    style="min-height:calc(100vh - 83px)"
  >
    <q-card flat bordered style="width:300px;">
      <q-tabs
        v-model="tab"
        no-caps
        class="bg-primary text-white"
        indicator-color="orange"
      >
        <q-tab name="input" label="INPUT" />
        <q-tab name="riwayat" label="RIWAYAT" />
      </q-tabs>
      <q-tab-panels v-model="tab" style="height: calc(100% - 48px);">
        <q-tab-panel name="input" class="q-pa-none column">
          <q-card-section class="q-gutter-md">
            <q-input
              dense
              outlined
              readonly
              label="Tanggal"
              v-model="laporan.tanggal"
            ></q-input>
            <q-input
              dense
              outlined
              label="Lokasi"
              v-model="laporan.lokasi"
            ></q-input>
            <q-input
              dense
              outlined
              label="Deksripsi"
              type="textarea"
              v-model="laporan.deskripsi"
            ></q-input>
            <q-uploader
              :factory="uploadFn"
              :max-files="1"
              ref="uploader"
              auto-upload
              hide-upload-btn
              label="Upload Gambar"
              flat
              bordered
              style="width: 266px;"
            />
          </q-card-section>
          <q-card-section style="flex-grow: 99;"></q-card-section>
          <q-btn
            unelevated
            class="no-border-radius full-width"
            color="primary"
            @click="save"
            :disable="laporan.lokasi == ''"
            >laporkan</q-btn
          >
        </q-tab-panel>
        <q-tab-panel name="riwayat" class="q-px-none">
          <q-card-section>
            <q-input outlined label="Tanggal" v-model="date" readonly>
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      minimal
                      v-model="date"
                      mask="YYYY-MM-DD"
                      @input="getRiwayat(filter)"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          @click="
                            date = null;
                            getRiwayat();
                          "
                          label="Reset"
                          color="negative"
                          flat
                        />
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </q-card-section>
          <q-markup-table
            flat
            dense
            wrap-cells
            class="stickyTable bg-grey-4 no-border-radius"
            separator="horizontal"
            style="height:calc(100vh - 290px)"
          >
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Loksasi</th>
                <th>
                  <q-btn-dropdown
                    dense
                    flat
                    color="black"
                    size="sm"
                    :label="filter"
                    dropdown-icon="filter_alt"
                  >
                    <q-list dense>
                      <q-item
                        clickable
                        @click="getRiwayat('All')"
                        v-close-popup
                      >
                        <q-item-section>
                          <q-item-label>All</q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        @click="getRiwayat('Selesai')"
                        v-close-popup
                      >
                        <q-item-section>
                          <q-item-label>Selesai</q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        @click="getRiwayat('Belum Selesai')"
                        v-close-popup
                      >
                        <q-item-section>
                          <q-item-label>Belum Selesai</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="(val, i) in riwayat" :key="i" @click="showDialog(val)">
                <td>{{ val.tanggal | moment("DD MMM YYYY") }}</td>
                <td>{{ val.lokasi }}</td>
                <td v-if="val.is_handled == 0">Belum Selesai</td>
                <td v-if="val.is_handled == 1">Selesai</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-card-section class="row justify-center q-py-none">
            <q-pagination
              dense
              v-model="pagination.current"
              :max="pagination.max"
              input
              input-class="text-orange-10"
              @input="getRiwayat(filter)"
            />
          </q-card-section>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </div>
</template>
<script>
import moment from "moment";
import DialogLaporanDetail from "@/components/DialogLaporanDetail";
export default {
  data() {
    return {
      uploadURL: process.env.VUE_APP_BASE_URL + "/upload.php",
      user: {},
      tab: "input",
      filter: "All",
      date: null,
      riwayat: [],
      laporan: {
        tanggal: moment().format("YYYY-MM-DD"),
        lokasi: "",
        deskripsi: "",
        nama_file: "",
      },
      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    await this.getRiwayat(this.filter);
  },
  methods: {
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    showDialog(val) {
      this.$q
        .dialog({
          component: DialogLaporanDetail,
          parent: this,
          data: val,
        })
        .onOk((resp) => {
          this.getRiwayat(this.filter);
        });
    },
    uploadFn(files) {
      let theFile = files[0];
      let newFileName =
        moment().format("YYYY-MM-DD-HH-mm-ss") + "_" + files[0].name;
      this.laporan.nama_file = newFileName;
      var formData = new FormData();
      formData.append("file", theFile);
      formData.append("newname", newFileName);
      formData.append("asal", "laporan");

      this.$http.post("/upload.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    async getRiwayat(fil) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.filter = fil;

      let offset = (this.pagination.current - 1) * this.pagination.limit;
      let resp = await this.$http.get(
        `/pengaturan/laporan/riwayat/1/${fil}/${this.date}/${this.pagination.limit}/${offset}`
      );
      this.riwayat = resp.data;

      resp = await this.$http.get(
        `/pengaturan/laporan/riwayat/1/${fil}/${this.date}`
      );
      this.pagination.max = Math.ceil(resp.data.length / this.pagination.limit);
      this.$q.loading.hide();
    },
    async save() {
      await this.$http.post(`/pengaturan/laporan/upload/1`, this.laporan);
      // 1 adalah id_musyrif sementara
      await this.getRiwayat(this.filter);
      this.laporan = {
        tanggal: moment().format("YYYY-MM-DD"),
        lokasi: "",
        deskripsi: "",
        nama_file: "",
      };
      this.$refs.uploader.reset();
      this.$q.notify({
        message: "Laporan Berhasil Disimpan",
        color: "positive",
      });
    },
  },
};
</script>
