<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-markup-table>
        <tr>
          <td>Lokasi</td>
          <td>:</td>
          <td>{{ data.lokasi }}</td>
        </tr>
        <tr>
          <td>Deskripsi</td>
          <td>:</td>
          <td>{{ data.deskripsi }}</td>
        </tr>
        <tr>
          <td>Gambar</td>
          <td>:</td>
          <td><a v-if="data.nama_file == ''">-</a></td>
        </tr>
      </q-markup-table>

      <img
        v-if="data.nama_file != ''"
        :src="src"
        spinner-color="white"
        style="max-width:300px"
      />

      <q-card-actions align="right">
        <q-btn
          flat
          label="close"
          color="negative"
          @click="onCancelClick"
        ></q-btn>
        <q-btn
          unelevated
          v-if="data.is_handled == '0'"
          v-can="['WAKA']"
          color="primary"
          label="Selesai"
          @click="handle"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      src: null
    };
  },
  mounted() {
    this.src = `../laporan_kerusakan/${this.data.nama_file} `;
  },
  methods: {
    async handle() {
      await this.$http.put(`/pengaturan/laporan/handle/${this.data.id}`);
      this.$q.notify({
        message: "Status Telah Berubah",
        color: "positive"
      });
      this.onOKClick();
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {};
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    }
  }
};
</script>
